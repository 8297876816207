<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.bitacora.items"
         :items-per-page="15"
         class="elevation-1"
         item-key="bitacora_id"
         :options.sync="options"
         :page.sync="page"
         :server-items-length="totalItems"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
            </v-toolbar>
         </template>
      </v-data-table>
   </div>
</template>

<script>

export default {
   data() {
      return {
         headers: [
            { text: "ID", value: "bitacora_id" },
            { text: "Usuario", value: "username" },
            { text: "Fecha Hora", value: "fecha_hora" },
            { text: "Acción", value: "action" },
         ],
         isloading: true,
         search: "",
         totalItems: 0,
         page: 1,
         options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["bitacora_id"],
            sortDesc: [true],
         },
      };
   },
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getBitacora", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
            });
            this.rows = this.$store.state.bitacora.items;
            this.totalItems = +this.$store.state.bitacora.totalItems;
            localStorage.setItem("bitacora_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      try {
         if (localStorage.getItem("bitacora_options")) {
            this.options = JSON.parse(localStorage.getItem("bitacora_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: ["bitacora_id"],
               sortDesc: [true],
            };
            localStorage.setItem("bitacora_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["bitacora_id"],
            sortDesc: [true],
         };
         localStorage.setItem("bitacora_options", JSON.stringify(this.options));
      } finally {
         this.initialize();
      }
   },
};
</script>

<style></style>
