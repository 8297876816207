<template>
   <Bitacora />
</template>

<script>
import Bitacora from "../components/Bitacora/Bitacora.vue";

export default {
   components: {
      Bitacora,
   },
};
</script>

<style></style>
